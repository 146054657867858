import React, { useState, useEffect, useContext, useCallback} from "react";
import {
  Container

} from "./styles";
import IframeComponent from "../../../components/IframeSlots";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import Nav from "../../../components/Nav/index";
import CardStrategy from "../../../components/CardStrategyMines";
import ImageBackgroud from "../../../assets/Mines/Mines-bg.png";
import ImageGame from "../../../assets/Mines/bomba.png";


import { AuthContext } from "../../../context/auth";


const Mines = () => {

  const { id } = useParams();
  const { user, gamesApi } = useContext(AuthContext);
  const [game, setGame] = useState();
  const [strategy1, setStrategy1] = useState({});

  
  useEffect(()=>{
    function getGame(){
      gamesApi.forEach(element => {
        if(element.id === id){
          setGame(element)
        }
      });
    }
    getGame()
  }, [id, gamesApi])

  
  function sortearNumero() {
    return Math.floor(Math.random() * (10 - 4 + 1)) + 4;
}

function validade() {
  const agora = new Date();
  agora.setMinutes(agora.getMinutes() + 3);

  const horas = agora.getHours().toString().padStart(2, '0');
  const minutos = agora.getMinutes().toString().padStart(2, '0');

  return `${horas}:${minutos}`;
}


  const generateStrategy = () => {
    const minProtecoes = 2;
    const maxProtecoes = 3;
    const protecoes =
      Math.floor(Math.random() * (maxProtecoes - minProtecoes + 1)) +
      minProtecoes;

    const minMinas = 1;
    const maxMinas = 3;
    const minas =
      Math.floor(Math.random() * (maxMinas - minMinas + 1)) + minMinas;

    
//  TRECHO QUE SALVA NO LOCALSTORAGE
    const dadosParaArmazenar = {
      Mines: {
        protecoes: protecoes,
        minas: minas,
        validade: validade(),
        bombPositions: generateBombPosition(),
      },
      horarioAtual: Date.now(),
    };

    setStrategy1(dadosParaArmazenar);
    localStorage.setItem("Mines", JSON.stringify(dadosParaArmazenar));


    return {
      protecoes: protecoes,
      minas: minas,
      validade: validade(),
      bombPositions: generateBombPosition(),
    };
  };

  const generateBombPosition = () => {
    const numSquares = 25;
    const numBombs = 3;
    const bombPositions = [];

    while (bombPositions.length < numBombs) {
      const position = Math.floor(Math.random() * numSquares);
      if (!bombPositions.includes(position)) {
        bombPositions.push(position);
      }
    }

    return bombPositions;
  };

  const [strategy, setStrategy] = useState({});
  const [canGenerateSignal, setCanGenerateSignal] = useState(true);

  const generateMinesField = () => {
    setCanGenerateSignal(false);
    setStrategy(generateStrategy());
  };

  const checkSignalExpiration = useCallback(() => {
    const searchedValue = localStorage.getItem("Mines");

    if (searchedValue !== null) {
      const valores = JSON.parse(searchedValue);
      const horarioFuturo = valores.horarioAtual + 3 * 60 * 1000;
      if (Date.now() >= horarioFuturo) {
        setStrategy1({});
        localStorage.removeItem("Mines");
        setCanGenerateSignal(true);
      } else {
        setStrategy1(valores);
        setCanGenerateSignal(false);
      }
    } else {
      setCanGenerateSignal(true);
    }
  }, []);

  useEffect(() => {
    checkSignalExpiration(); 
    const interval = setInterval(() => {
      checkSignalExpiration();
    }, 1 * 60 * 1000);
    return () => clearInterval(interval); 
  }, [checkSignalExpiration]);

  

  return (
    <>

    <Nav />
    <Container canGenerateSignal={canGenerateSignal}>

      <Link to="/slotsHome" >
        <AiOutlineArrowLeft color="#fff" size="32" />
      </Link>
   
   <div style={ canGenerateSignal ?  { marginTop: 20} :{ marginTop: 150} }>
      <CardStrategy
        imgBackground={ImageBackgroud}
        imgGame={ImageGame}
        imgGameHeigth={206}
        imgGameWidth={204}
        updated={generateMinesField}
        canGenerateSignal={canGenerateSignal}
        values01={strategy1.Mines ? strategy1.Mines.protecoes : undefined}
        values02={strategy1.Mines ? strategy1.Mines.minas : undefined}
        values03={strategy1.Mines ? strategy1.Mines.validade : undefined}
        bombPositions={strategy1.Mines  ? strategy1.Mines.bombPositions : []}  
        button ={ false }      
      />

   </div>


    
      <IframeComponent link={game && game.link_iframe} />
      
      <br />
    </Container>
    </>
  );
};

export default Mines;

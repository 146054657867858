import React, { useState, useEffect, useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { AuthContext } from "../../../context/auth";
import IframeComponent from "../../../components/Iframes/";
import UserImg from "../../../assets/user.jpg";
import Nav from "../../../components/Nav/index";
import { useParams } from "react-router-dom";
import {
  Container,
  General,
  Header,
  ImageHeader,
  Main,
  MainMsg,
  MainName,
  MainBody,
} from "./styles";

const BacBo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [game, setGame] = useState();
  const [currentStrategy, setCurrentStrategy] = useState("");
  const [mensagemAtual, setMensagemAtual] = useState("Analisando padrões...");
  const { id } = useParams();
  const { user, gamesApi } = useContext(AuthContext);

  const getGame = useCallback(() => {
    gamesApi.forEach((element) => {
      if (element.id === id) {
        setGame(element);
      }
    });
  }, [gamesApi, id]);

  useEffect(() => {
    getGame();
  }, [getGame]);

  const fetchBacBoData = async () => {
    setIsLoading(true);
    const apiUrl = "https://api-neobet.onrender.com/scrapping/all";
    try {
      const response = await Axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
        }
      });
  
      const dragonGame = response.data.find(game => game.game === "Dragon");
      if (!dragonGame) {
        throw new Error("Jogo Dragon não encontrado na resposta da API.");
      }
  
      
      const ultimosNumeros = dragonGame.results.split(',').map(item => {
        if (item.trim() === 'L') return 'DRAGON';
        if (item.trim() === 'R') return 'TIGER';
        return 'Tie';
      });
      
      const strategy = processarEstrategia(ultimosNumeros);
      setCurrentStrategy(strategy);
  
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const processarEstrategia = useCallback((numeros) => {
  
    const frases = [
      "Estou buscando uma oportunidade...",
      "Traçando uma nova estratégia...",
      "Digitando...",
      "Investigando tendências",
      "Analisando próxima entrada..."
    ];
    const indiceSorteado = Math.floor(Math.random() * frases.length);

      switch (true) {      
        // GALE 2 AZUL
        case (numeros.length >= 6 && numeros[0] === "DRAGON" && numeros[1] === "DRAGON" && numeros[2] === "DRAGON" && numeros[3] === "DRAGON" && numeros[4] === "DRAGON" && numeros[5] === "DRAGON"):
          return "GALE 2 | Entrar no 🟡";

        // GALE 1 AZUL
        case (numeros.length >= 5 && numeros[0] === "DRAGON" && numeros[1] === "DRAGON" && numeros[2] === "DRAGON" && numeros[3] === "DRAGON" && numeros[4] === "DRAGON"):
          return "GALE 1 | Entrar no 🟡";

        // ESTRATÉGIA AZUL
        case (numeros.length >= 4 && numeros[0] === "DRAGON" && numeros[1] === "DRAGON" && numeros[2] === "DRAGON" && numeros[3] === "DRAGON"):
          return "Entrada confirmada no 🟡";

        // WIN NO AZUL
        case (numeros.length >= 5 && numeros[0] === "TIGER" && numeros[1] === "DRAGON" && numeros[2] === "DRAGON" && numeros[3] === "DRAGON" && numeros[4] === "DRAGON"):
          return "Última cor: 🟡 | Deu Green!!! 🤑💰 ";

        // GALE 2 VERMELHO
        case (numeros.length >= 6 && numeros[0] === "TIGER" && numeros[1] === "TIGER" && numeros[2] === "TIGER" && numeros[3] === "TIGER" && numeros[4] === "TIGER" && numeros[5] === "TIGER"):
          return "GALE 2 | Entrar no 🔴";

        // GALE 1 VERMELHO
        case (numeros.length >= 5 && numeros[0] === "TIGER" && numeros[1] === "TIGER" && numeros[2] === "TIGER" && numeros[3] === "TIGER" && numeros[4] === "TIGER"):
          return "GALE 1 | Entrar no 🔴";

        // ESTRATÉGIA VERMELHO
        case (numeros.length >= 4 && numeros[0] === "TIGER" && numeros[1] === "TIGER" && numeros[2] === "TIGER" && numeros[3] === "TIGER"):
          return "Entrada confirmada no 🔴";

        // WIN NO VERMELHO
        case (numeros.length >= 5 && numeros[0] === "DRAGON" && numeros[1] === "TIGER" && numeros[2] === "TIGER" && numeros[3] === "TIGER" && numeros[4] === "TIGER"):
          return "Última cor: 🔴 | Deu Green!!! 🤑💰 ";


        // ESTRATÉGI AZUL (INVERTIDA)
        case (numeros.length >= 4 && numeros[0] === "DRAGON" && numeros[1] === "TIGER" && numeros[2] === "DRAGON" && numeros[3] === "TIGER"):
          return "Entrada confirmada no 🟡";
        //WIN AZUL (INVERTIDA)
        case (numeros.length >= 5 && numeros[0] === "TIGER" && numeros[1] === "DRAGON" && numeros[2] === "TIGER" && numeros[3] === "DRAGON" && numeros[4] === "TIGER"):
          return "Última cor: 🟡 | Deu Green!!! 🤑💰 ";
        // ESTRATÉGI VERMELHO (INVERTIDA)
        case (numeros.length >= 4 && numeros[0] === "TIGER" && numeros[1] === "DRAGON" && numeros[2] === "TIGER" && numeros[3] === "DRAGON"):
          return "Entrada confirmada no 🔴";
        //WIN VERMELHO (INVERTIDA)
        case (numeros.length >= 5 && numeros[0] === "DRAGON" && numeros[1] === "TIGER" && numeros[2] === "DRAGON" && numeros[3] === "TIGER" && numeros[4] === "DRAGON"):
          return "Última cor: 🔴 | Deu Green!!! 🤑💰";

        //POSSÍVEL ENTRADA (Azul)
        case (numeros.length >= 3 && numeros[0] === "DRAGON" && numeros[1] === "DRAGON" && numeros[2] === "DRAGON"):
          return "ATENÇÃO, Possível Entrada!";

        //POSSÍVEL ENTRADA (Vermelho)
        case (numeros.length >= 3 && numeros[0] === "TIGER" && numeros[1] === "TIGER" && numeros[2] === "TIGER"):
          return "ATENÇÃO, Possível Entrada!";

        default:
          return frases[indiceSorteado];
      }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchBacBoData();
      setMensagemAtual("Digitando...");
    }, 10000);
    
    return () => clearInterval(intervalId);
  }, [fetchBacBoData]);

  return (
    <>

    <Nav />
    <Container>
      <Link to="/cassinoHome">
        <AiOutlineArrowLeft color="#fff" size="32" />
      </Link>
      <General>
        <Header>
          <ImageHeader src={UserImg} alt="" />
        </Header>
        <Main>
          <MainMsg>
            <MainName>{game && game.analyst_id.name}</MainName>
            <MainBody>{currentStrategy || mensagemAtual}</MainBody>
          </MainMsg>
        </Main>
      </General>
      {/* <PanelRoleta strategy={currentStrategy} /> */}
      <IframeComponent link={game && game.link_iframe} />
    </Container>
    </>
  );
};

export default BacBo;
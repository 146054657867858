import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/auth";
import { RiLogoutCircleRLine } from "react-icons/ri";
import Coroa from "../../assets/coroa.svg";
import Logo from "../../assets/logovsg.svg";
import { Container, CenterContent, LogoContainer, OptionImg, Button, ButtonSair, Text, Logout, BackButton } from "./styles";

export default function Nav() {
  const navigate = useNavigate();
  const { logged, logout } = useContext(AuthContext);

  const executarLogout = async () => {
    await logout();
    navigate("/");
  };

  return (
    <Container logged={logged}>
      {logged ? (
        <>
          <LogoContainer>
            <OptionImg src={Logo} alt="Logo" onClick={() => navigate('/home')} />
          </LogoContainer>
          <ButtonSair>
                <Logout onClick={() => executarLogout()}>
                  <RiLogoutCircleRLine size={34} color="#fff" />
                </Logout>
            </ButtonSair>
        </>
      ) : (
        <CenterContent>
          <OptionImg src={Logo} alt="Logo" onClick={() => navigate('/home')} />
          <Button onClick={() => navigate('/login')}>
            <img src={Coroa} alt="Coroa" />
            <Text>Desbloquear PRO</Text>
          </Button>
        </CenterContent>
      )}
    </Container>
  );
}

import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Container,
  General,
  Header,
  ImageHeader,
  Main,
  MainName,
  MainBody,
} from "./styles";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Link } from "react-router-dom";
import UserImg from "../../../assets/user.jpg";
import IframeComponent from "../../../components/IframeSlots";
import styled from "styled-components";
import LinkGame from "../../../config/linkGames.json";
import Nav from "../../../components/Nav/index";

import { AuthContext } from "../../../context/auth";
import { useParams } from "react-router-dom";

import CardStrategy from "../../../components/CardStrategy";

import ImageBackgroud from "../../../assets/Rabbit/FortuneRabbit.jpg";
import ImageGame from "../../../assets/dragon-C7X30pEz.png";

const FortuneDragon = () => {
  const { id } = useParams();
  const { user, gamesApi } = useContext(AuthContext);
  const [game, setGame] = useState();

  useEffect(() => {
    function getGame() {
      gamesApi.forEach((element) => {
        if (element.id === id) {
          setGame(element);
        }
      });
    }
    getGame();
  }, [id, gamesApi]);

  function sortearNumero() {
    return Math.floor(Math.random() * (10 - 4 + 1)) + 4;
  }

  function validade() {
    const agora = new Date();
    agora.setMinutes(agora.getMinutes() + 3);

    const horas = agora.getHours().toString().padStart(2, "0");
    const minutos = agora.getMinutes().toString().padStart(2, "0");

    return `${horas}:${minutos}`;
  }

  const [strategy, setStrategy] = useState({});
  const [canGenerateSignal, setCanGenerateSignal] = useState(true);

  const generateStrategy = useCallback(() => {
    try {
      const dadosParaArmazenar = {
        FortuneDragon: {
          normal: sortearNumero(),
          turbo: sortearNumero(),
          validade: validade(),
        },
        horarioAtual: Date.now(),
      };
      setStrategy(dadosParaArmazenar);
      localStorage.setItem("FortuneDragon", JSON.stringify(dadosParaArmazenar));
      setCanGenerateSignal(false);
    } catch (error) {
      console.error("Erro ao gerar os dados:", error);
    }
  }, []);

  const checkSignalExpiration = useCallback(() => {
    const searchedValue = localStorage.getItem("FortuneDragon");
    if (searchedValue !== null) {
      const valores = JSON.parse(searchedValue);
      const horarioFuturo = valores.horarioAtual + 3 * 60 * 1000;
      if (Date.now() >= horarioFuturo) {
        setStrategy({});
        localStorage.removeItem("FortuneDragon");
        setCanGenerateSignal(true);
      } else {
        setStrategy(valores);
        setCanGenerateSignal(false);
      }
    } else {
      setCanGenerateSignal(true);
    }
  }, []);

  useEffect(() => {
    checkSignalExpiration();
    const interval = setInterval(() => {
      checkSignalExpiration();
    }, 1 * 60 * 1000);
    return () => clearInterval(interval);
  }, [checkSignalExpiration]);

  return (
    <>
      <Nav />
      <Container>
        <Link to="/slotsHome" style={{ marginTop: 50 }}>
          <AiOutlineArrowLeft color="#fff" size="32" />
        </Link>

        <div style={{ marginTop: 50 }}>
          <CardStrategy
            imgBackground={ImageBackgroud}
            imgGame={ImageGame}
            imgGameHeigth="247"
            imgGameWidth="247"
            updated={generateStrategy}
            canGenerateSignal={canGenerateSignal}
            values01={strategy.FortuneDragon ? strategy.FortuneDragon.normal : undefined}
            values02={strategy.FortuneDragon ? strategy.FortuneDragon.turbo : undefined}
            values03={strategy.FortuneDragon ? strategy.FortuneDragon.validade : undefined}
          />
        </div>

        <IframeComponent link={game && game.link_iframe} />
      </Container>
    </>
  );
};

export default FortuneDragon;

import styled from "styled-components";
import DiamondImg from "../../../assets/Mines/mines.png";
import Vazio from "../../../assets/Mines/minesVazio.png";
import PropTypes from "prop-types";

export default function MinesMatrix({bombPositions}) {
  let matrix;
  
  do {
    matrix = Array.from({ length: 5 }, () =>
      Array.from({ length: 5 }, () => Math.random() < 0.5 ? 0 : 2)
    );
  } while (matrix.flat().filter(value => value === 2).length < 3);

  return (
    <MinesField>
      <Board>
        {matrix.map((row, rowIndex) => (
          row.map((value, colIndex) => (
            <Square
              key={`${rowIndex}-${colIndex}`}
              value={value}
              isBomb={bombPositions.includes(rowIndex * 5 + colIndex)}
            />
          ))
        ))}
      </Board>
    </MinesField>
  );
}

MinesMatrix.propTypes = {
  bombPositions: PropTypes.node.isRequired,
};

function Square({ value, isBomb }) {
  return (
    <>
      {isBomb ? (
        <Squared>
          <Diamond src={DiamondImg} alt="Bomb" />
        </Squared>
      ) : value === 0 ? (
        <Vazio1>
          <Vazio2>
            <Diamond src={Vazio} alt="Empty" />
          </Vazio2>
        </Vazio1>
      ) : (
        <SquaredVazio>
          <Diamond src={Vazio} alt="Empty" />
        </SquaredVazio>
      )}
    </>
  );
}

Square.propTypes = {
  value: PropTypes.node.isRequired,
  isBomb: PropTypes.node.isRequired,
};


export const MinesField = styled.div`
  background-color: rgba(0, 0, 0, 0.27);
  display: flex;
  width: 100%;
  max-width: 700px;
  height: 150px;
  margin-top: 30px;
  border-radius: 7px;
  justify-content: center;
  align-items: center;
  margin-bottom: 200px;
  box-shadow: 0px 0px 8px 4px rgb(8, 63, 108);
  padding: 50px 0px;

`;

export const Board = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  gap: 3px;
`;

const Squared = styled.div`
  height: 25x;
  width: 25px;
  background: rgb(8, 63, 108);
  border: 1px solid rgb(1, 85, 149);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SquaredVazio = styled.div`
  height: 25px;
  width: 25px;
  border: 1px solid;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Vazio1 = styled.div`
  height: 25px;
  width: 25px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Vazio2 = styled.div`
  height: 25px;
  width: 25px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Diamond = styled.img`
  height: 25px;
  width: 25px;
`;

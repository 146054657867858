/* eslint-disable react-hooks/exhaustive-deps */
import {
  Container,
  General,
  Header,
  ImageHeader,
  Main,
  MainMsg,
  MainName,
  MainBody,
  HighlightedText,
} from "./styles";
import React, { useState, useEffect, useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { AuthContext } from "../../../context/auth";
import IframeComponent from "../../../components/Iframes/";
import PanelRoleta from "../BacboPanel";
import UserImg from "../../../assets/user.jpg";
import Nav from "../../../components/Nav/index";
import { useParams } from "react-router-dom";


const Roletas = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [game, setGame] = useState();
  const [currentStrategy, setCurrentStrategy] = useState("");
  const [mensagemAtual, setMensagemAtual] = useState("Analisando padrões...");
  const { id } = useParams();
  const { user, gamesApi } = useContext(AuthContext);

  const getGame = useCallback(() => {
    gamesApi.forEach((element) => {
      if (element.id === id) {
        setGame(element);
      }
    });
  }, [gamesApi, id]);

  useEffect(() => {
    getGame();
  }, [getGame]);

  const fetchBacBoData = async () => {
    setIsLoading(true);
    const apiUrl = "https://api-neobet.onrender.com/scrapping/all";
    try {
      const response = await Axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
        }
      });
  
      const megaRouletteGame = response.data.find(game => game.game === "Roulette_Fire_Blaze");
      if (!megaRouletteGame) {
        throw new Error("Jogo Mega_Roulette não encontrado na resposta da API.");
      }
  
      const ultimosNumeros = megaRouletteGame.results.split(',').map(item => parseFloat(item.trim()));
  
      const numerosFiltrados = ultimosNumeros.filter(item => !isNaN(item));
      
      const numerosLimitados = numerosFiltrados.slice(0, 20);
      
      const strategy = processarEstrategia(numerosLimitados);
      setCurrentStrategy(strategy);
  
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const processarEstrategia = useCallback((numeros) => {
  
    const frases = [
      `Estou buscando uma oportunidade...`,
      `O último número foi ${numeros[0]}, estou traçando uma nova estratégia...`,
      `Essa roleta possui muitas estratégias avançadas! 😈`,
      `Digitando...`,
      `${numeros[0]} foi o último... Acho que vai bater a estratégia... 🧐`,
      `Investigando tendências`,
      `Lembre-se de defender todas as entradas sempre no 0!`,
      `Seja paciente e aguarde o momento ideal da entrada, não costumamos falhar!`,
      `O último número foi ${numeros[0]}, Nenhuma estratégia bateu ainda...`,
      `Não durma, está quase batendo a estratégia...`,
    ];
    const indiceSorteado = Math.floor(Math.random() * frases.length);
  
    const numerosPretos = [2, 6, 4, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35];
    const numerosVermelhos = [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36];
    
    const lateralSuperior = [9, 22, 18, 29, 7, 31, 14, 20, 1]
    const lateralInferior = [4, 21, 2, 25, 17, 34, 6, 27, 13]
    const lateralDireita = [28, 12, 35, 3, 26, 0, 32, 15, 19]
    const lateralEsquerda = [23, 8, 30, 11, 36, 10, 5, 24, 16, 33]

    const SuperiorDireita = lateralSuperior.concat(lateralDireita)
    const SuperiorEsquerda = lateralSuperior.concat(lateralEsquerda)

    const InferiorDireita = lateralInferior.concat(lateralDireita)
    const InferiorEsquerda = lateralInferior.concat(lateralEsquerda)

    const percent = 0.8;

    switch (true) {
      //ESTRATÉGIAS AVANÇADAS ZAYED
      case (numeros.length >= 10 && numeros.slice(0, 10).filter(num => SuperiorEsquerda.includes(num)).length >= 10 * percent):
        return "ENTRADA CONFIRMADA! Entrar nos números 10 e 14 Com 3 Vizinhos!!!"

      case (numeros.length >= 10 && numeros.slice(0, 10).filter(num => InferiorDireita.includes(num)).length >= 10 * percent):
        return "ENTRADA CONFIRMADA! Entrar nos números 0 e 25 Com 3 Vizinhos!!!"

        case (numeros.length >= 10 && numeros.slice(0, 10).filter(num => InferiorEsquerda.includes(num)).length >= 10 * percent):
        return "ENTRADA CONFIRMADA! Entrar nos números 23 e 6 Com 3 Vizinhos!!!"
      
      case (numeros.length >= 10 && numeros.slice(0, 10).filter(num => SuperiorDireita.includes(num)).length >= 10 * percent):
        return "ENTRADA CONFIRMADA! Entrar nos números 26 e 18 Com 3 Vizinhos!!!";

      // ESTRATÉGIA SEQUENCIA DE PRETOS = VERMELHO
      case (numeros.length >= 10 && numeros.slice(0, 8).every(num => numerosPretos.includes(num))):
        return "Tendência muito forte, vamos aguardar um pouco...";

      case (numeros.length >= 8 && numeros.slice(0, 6).every(num => numerosPretos.includes(num))):
        return "DEFESA 2 | Entrar no 🔴";
  
        case (numeros.length >= 7 && numeros.slice(0, 5).every(num => numerosPretos.includes(num))):
          return "DEFESA 1 | Entrar no 🔴";
    
        case (numeros.length >= 6 && numeros.slice(0, 4).every(num => numerosPretos.includes(num))):
          return "Entrada confirmada no 🔴 LEMBRE-SE DE DEFENDER O ZERO.";

        case (numeros.length >= 8 && numeros.slice(1, 6).every(num => numerosPretos.includes(num)) && numerosVermelhos.includes(numeros[0] || numeros[0] === 0)):
          return "Última cor: 🔴 | Deu Green!!! 🤑💰 ";

        case (numeros.length >= 5 && numeros.slice(0, 4).every(num => numerosPretos.includes(num))):
          return "SEQUÊNCIA FORTE DE NÚMEROS PRETOS. ATENÇÃO! Possível entrada.";

      // ESTRATÉGIA SEQUENCIA DE VERMELHOS = PRETO
      case (numeros.length >= 10 && numeros.slice(0, 8).every(num => numerosVermelhos.includes(num))):
        return "Tendência muito forte, vamos aguardar um pouco...";

      case (numeros.length >= 8 && numeros.slice(0, 7).every(num => numerosVermelhos.includes(num))):
        return "DEFESA 2 | Entrar no ⚫";

        case (numeros.length >= 7 && numeros.slice(0, 6).every(num => numerosVermelhos.includes(num))):
          return "DEFESA 1 | Entrar no ⚫";

        case (numeros.length >= 6 && numeros.slice(0, 5).every(num => numerosVermelhos.includes(num))):
          return "Entrada confirmada no ⚫ LEMBRE-SE DE DEFENDER O ZERO.";

        case (numeros.length >= 8 && numeros.slice(1, 6).every(num => numerosVermelhos.includes(num)) && numerosPretos.includes(numeros[0] || numeros[0] === 0)):
          return "Última cor: ⚫ | Deu Green!!! 🤑💰 ";

        case (numeros.length >= 5 && numeros.slice(0, 4).every(num => numerosVermelhos.includes(num))):
          return "SEQUÊNCIA FORTE DE NÚMEROS VERMELHOS. ATENÇÃO! Possível entrada.";

      // ESTRATÉGIA DE NÚMEROS ÍMPARES
      case (numeros.length >= 6 && numeros.slice(0, 5).every(num => num % 2 === 0)):
        return "Entrada confirmada no Ímpar. LEMBRE-SE DE DEFENDER O ZERO.";

        case (numeros.length >= 7 && numeros.slice(1, 6).every(num => num % 2 === 0) && (numeros[0] % 2 !== 0 || numeros[0] === 0)):
          return `Último número: ${numeros[0]} Ímpar | Deu Green!!! 🤑💰 `;
  
      // ESTRATÉGIA DE NÚMEROS PARES
      case (numeros.length >= 6 && numeros.slice(0, 5).every(num => num % 2 !== 0)):
        return "Entrada confirmada no Par. LEMBRE-SE DE DEFENDER O ZERO.";

        case (numeros.length >= 7 && numeros.slice(1, 6).every(num => num % 2 !== 0) && (numeros[0] % 2 === 0 || numeros[0] === 0)):
          return `Último número: ${numeros[0]} Par | Deu Green!!! 🤑💰 `;
  
      // ESTRATÉGIA NÚMEROS GRANDES
      case (numeros.length >= 8 && numeros.slice(0, 7).every(num => num >= 1 && num <= 18)):
        return "Último número bateu entre 19-36 | Deu Green!!! 🤑💰";

      case (numeros.length >= 7 && numeros.slice(0, 6).every(num => num >= 1 && num <= 18)):
        return "Entrada confirmada no 19-36. LEMBRE-SE DE DEFENDER O ZERO.";

      // ESTRATÉGIA NÚMEROS PEQUENOS
      case (numeros.length >= 8 && numeros.slice(0, 7).every(num => num >= 19 && num <= 36)):
        return "Último número bateu entre 1-18 | Deu Green!!! 🤑💰";

      case (numeros.length >= 7 && numeros.slice(0, 6).every(num => num >= 19 && num <= 36)):
        return "Entrada confirmada no 1-18. LEMBRE-SE DE DEFENDER O ZERO.";
  
      // ESTRATÉGIA DE TROCA DE CORES
      case (numeros.length >= 6 &&
        numerosPretos.includes(numeros[4]) &&
        numerosVermelhos.includes(numeros[3]) && 
        numerosPretos.includes(numeros[2]) && 
        numerosVermelhos.includes(numeros[1]) && 
        numerosPretos.includes(numeros[0])):
        return "Entrada confirmada no 🔴 SEM DEFESA. LEMBRE-SE DE DEFENDER O ZERO.";
  
      case (numeros.length >= 6 &&
        numerosVermelhos.includes(numeros[4]) &&
        numerosPretos.includes(numeros[3]) &&
        numerosVermelhos.includes(numeros[2]) && 
        numerosPretos.includes(numeros[1]) &&
        numerosVermelhos.includes(numeros[0])):
        return "Entrada confirmada no ⚫ SEM DEFESA. LEMBRE-SE DE DEFENDER O ZERO.";
  
      default:
        return frases[indiceSorteado];
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchBacBoData();
      setMensagemAtual("Digitando...");
    }, 10000);
    
    return () => clearInterval(intervalId);
  }, [fetchBacBoData]);


  return (
    <>

    <Nav />
    <Container>
      <Link to="/cassinoHome">
        <AiOutlineArrowLeft color="#fff" size="32" />
      </Link>

      <General>
        <Header>
          <ImageHeader src={UserImg} alt="" />
        </Header>
        <Main>
        <MainMsg>
            <MainName>{game && game.analyst_id.name}</MainName>
            <MainBody>{currentStrategy || mensagemAtual}</MainBody>
          </MainMsg>
        </Main>
      </General>
      {/* <PanelRoleta strategy={currentStrategy} /> */}
      <IframeComponent link={game && game.link_iframe} />  
    </Container>
    </>
  );
};

export default Roletas;
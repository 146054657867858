// Em CardStrategy.jsx
import PropTypes from "prop-types";
import {
  Container,
  Body,
  Header,
  Image,
  ImageGame,
  Strategy,
  StrategyFild,
  StrategyFildIcon,
  StrategyFildText,
} from "./styles";
import Protection from "../../assets/Icons/protection.png";
import Mina from "../../assets/Icons/mina.png";   
import IconTime from "../../assets/Icons/timeIcon.png";
import Button from "../ButtonSignal";
import BombPositions from "../../pages/Games/Mines/BombPositions";

const CardStrategy = ({ imgBackground, imgGame, imgGameHeigth, imgGameWidth, updated, canGenerateSignal, values01, values02, values03, bombPositions, button }) => {
  return (
    <Container>
      <Body>  
        <Header>
          <Image src={imgBackground} alt="" />
          <ImageGame width={imgGameWidth} height={imgGameHeigth} src={imgGame} alt="" />
        </Header>

        <Strategy>
        <StrategyFild>
        <StrategyFildIcon src={Protection} alt="" className="white-icon" />
          <StrategyFildText>Proteções: {values01 !== undefined ? values01 : ''}</StrategyFildText>
        </StrategyFild>
        <StrategyFild>
          <StrategyFildIcon src={Mina} alt="" />
          <StrategyFildText>Nº de Minas: {values02 !== undefined ? values02 : ''}</StrategyFildText>
        </StrategyFild>
        <StrategyFild>
          <StrategyFildIcon src={IconTime} alt="" />
          <StrategyFildText>Validade: {values03 !== undefined ? values03 : ''}</StrategyFildText>
        </StrategyFild>
      </Strategy>
      </Body>
    
      {!canGenerateSignal && <BombPositions bombPositions={bombPositions} />}
     
      {!canGenerateSignal && !button? null: <Button updated={updated} canGenerateSignal={canGenerateSignal} />  } 

    </Container>
  );
};

CardStrategy.propTypes = {
  imgBackground: PropTypes.node.isRequired,
  imgGame: PropTypes.string.isRequired,
  imgGameHeigth: PropTypes.string.isRequired,
  imgGameWidth: PropTypes.string.isRequired,
  updated: PropTypes.func.isRequired,
  canGenerateSignal: PropTypes.bool.isRequired,
  values01: PropTypes.string.isRequired,
  values02: PropTypes.string.isRequired,
  values03: PropTypes.string.isRequired,
  bombPositions: PropTypes.array.isRequired,
  generateNewMatrix: PropTypes.func.isRequired,
  generateClicked: PropTypes.bool.isRequired,
};

export default CardStrategy;

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    margin-top: -35px;
   flex-direction: column;
  }

`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  background: transparent;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
 margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-top: 50px;
    margin-bottom: 0px;
   flex-direction: column;
  }
 
`;
export const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
 
`;

export const Image = styled.img`
  height: 230px;
  max-height: 200px;
  max-width: 287px;
  border-radius: 13px;
  box-shadow: inset 23px 3px 40.4px 0px rgba(255, 0, 0, 0.3);
  background-size: cover;
  background-position: center;
  margin-right: -20px;
  background-size: cover;
  position: relative;
  z-index: 2;
  background-color: transparent;

  @media (max-width: 768px) {
    margin-right: 0px;
  }
`;

export const ImageGame = styled.img`
  z-index: 3;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Strategy = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px 0px;
  width: 473.321px;
  height: 199.668px;
  padding-right: 10px;
  border-radius: 22px;
  border: 1px solid #000;
  box-shadow: 1px 1px 1px #FFAB08;
  background: linear-gradient(
    59deg,
    rgba(44, 32, 57, 0.33) 40.14%,
    rgba(255, 255, 255, 0.07) 75.22%
  );

  backdrop-filter: blur(10px);
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    width: 95%;
    height: auto;
    padding: 10px 0px;
  }
`;

export const StrategyFild = styled.div`
  display: flex;
  align-items: center;
  padding-left: 30px;
  border-radius: 22px;
  box-shadow: 1px 1px 1px #FFAB08;
  border: 1px solid #000;
  background: linear-gradient(
    59deg,
    rgba(44, 32, 57, 0.33) 40.14%,
    rgba(255, 255, 255, 0.07) 75.22%
  );
  backdrop-filter: blur(10px);
  width: 301.285px;
  height: 42.786px;
`;

export const StrategyFildIcon = styled.img`
  width: 26.159px;
  height: 26.332px;
  box-shadow: inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-right: 10px;
`;

export const StrategyFildText = styled.span`
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
import styled from "styled-components";

export const Button = styled.button`
  border: none;
  width: 20em;
  height: 3.5em;
  min-height: 3.5em;
  border-radius: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: linear-gradient(90deg, #AA40FC 0%, #6742F4 50.32%, #09CFEC 100%);
  cursor: pointer;
  transition: all 450ms ease-in-out;

  &:hover {
    background: linear-gradient(90deg, #AA40FC 0%, #6742F4 50.32%, #09CFEC 100%);
    box-shadow: inset 0px 1px 0px 0px rgba(255, 255, 255, 0.4),
      inset 0px -4px 0px 0px rgba(0, 0, 0, 0.2),
      0px 0px 0px 4px rgba(255, 255, 255, 0.2),
      0px 0px 180px 0px #9917FF;
    transform: translateY(-2px);
  }

  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

export const Sparkle = styled.svg`
  fill: #AAAAAA;
  transition: all 800ms ease;
`;

export const Text = styled.span`
color: #FFF;
text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
font-family: Montserrat;
font-size: 16px;
font-style: normal;
line-height: normal;
letter-spacing: -1px;
`;
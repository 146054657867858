import React, { useEffect, useState, useContext } from "react";
import {
  Container,
  Titulo,
  GamesCircle,
  Operations,
  OperationsHeader,
  OperationsTitle,
  OperationsMain,
  OperationsMainHeader,
  OperationsMainSubtitle,
  Topic,
  OperationsMainCards,
  OperationsMainSubtitleTxt,
} from "./styles";

import Nav from "../Nav";
import Menu from "../Menu";
import GameCircle from "../Games/Circle";
import Cards from "../Games/Cards";
import InstallModal from "../InstallModal"; // Atualize o caminho para o arquivo do modal
import { useNavigate } from "react-router-dom";

import styled, { createGlobalStyle, keyframes } from "styled-components";

import Cassino from "../../assets/HomePhoto/Roleta.png";
import Slots from "../../assets/HomePhoto/Slots.png";
import Sports from "../../assets/HomePhoto/Sports.png";
import Crash from "../../assets/HomePhoto/Crash.png";

import { AuthContext } from "../../context/auth";
import { FaApple, FaAndroid } from "react-icons/fa";

import elite from "../../assets/MESTRE.png";
import { BsFillRocketTakeoffFill } from "react-icons/bs";
import { TbCards } from "react-icons/tb";

import AnalystImg from "../../assets/user.jpg";
import { toast } from "react-toastify";

import Modal from "react-modal";

Modal.setAppElement("#root");

const GlobalStyle = createGlobalStyle`
  body {
    position: fixed;   
    width: 100vw;      
    height: 100vh;     
    overflow: auto;    
    overflow-x: hidden;
    font-family: "Roboto", sans-serif; 
  }
  ::-webkit-scrollbar {
    width: 0 !important;
  }
  ::-webkit-scrollbar-thumb {
    background-color: transparent !important;
  }
`;

const blinkAnimation = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
    
  }
`;

const CustomButton = styled.button`
  background-image: url(${elite});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 170px;
  padding: 32px 0;
  margin-bottom: 200px;
  background-color: #56cca100;
  border: none;
  cursor: pointer;
  &.blink-button {
    animation: ${blinkAnimation} 0.8s linear infinite;
  }
`;
const InstallButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -210px; 
  margin-bottom: 250px;
`;

const InstallButton = styled.a`
  padding: 15px 20px;
  margin: 0 10px;
  background-color: #9cff000d;
  border: 1px solid #9cff0020;
  border-radius: 8px;
  text-decoration: none;
  font-weight: bold;
  color: #14b1a4; 
  display: flex; 
  align-items: center;
  justify-content: center;

  box-shadow: 0px 0px 10px rgba(0, 0, 255, 0.3);

  &:hover {
    background-color: #333;
  }
  @media (max-width: 720px) {
    font-size: 10px; 
    padding: 10px 15px; 
  }

  &:first-child {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    padding-right: 20px;
  }

  &:last-child {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    padding-left: 20px;
  }
`;
const CustomButton1 = styled.button`
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 170px;
  padding: 32px 0;
  margin-bottom: 100px;
  background-color: #56cca100;
  border: none;
  cursor: pointer;
  &.blink-button {
    animation: ${blinkAnimation} 1.5s linear infinite;
  }
`;

const Home = () => {

  const { user, gamesApi } = useContext(AuthContext);

  const initialPlayers = Array(6)
    .fill()
    .map(() => getRandomPlayers(253, 947));
  const [playersCounts, setPlayersCounts] = useState(initialPlayers);
  function getRandomPlayers(min = 0, max = 947) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const sports = ()=>{
    toast.warning("Disponível em breve...")
  }


  useEffect(() => {
    const updatePlayers = () => {
      setPlayersCounts((prevCounts) => {
        return prevCounts.map((count) => {
          let newCount = count + (Math.random() > 0.5 ? 1 : -1);
          if (newCount > 947) return 947;
          if (newCount < 253) return 253;
          return newCount;
        });
      });
    };

    const interval = setInterval(updatePlayers, 20000); // Atualizar a cada 1 segundo

    return () => clearInterval(interval);
  }, []);
  const [showModal, setShowModal] = useState(false); // Estado para controlar a exibição do modal
  const [modalType, setModalType] = useState(null); // Estado para determinar o tipo de modal ('iOS' ou 'Android')
  const navigate = useNavigate();
  const handleGameClick = (game) => {
    if (game === "Crash") {
      navigate("/crashHome");
    }
    if (game === "Cassino") {
      navigate("/cassinoHome");
    }

    if (game === "Sports") {
      navigate("/sportsHome");
    }
  };

  const openModal = (type) => {
    setModalType(type); 
    setShowModal(true); 
  };

  const closeModal = () => {
    setShowModal(false); 
  };


  return (
    <>
      <GlobalStyle />
      <Nav />
      <Container>
        <Titulo>Com quais jogos você deseja lucrar hoje?</Titulo>

        <GamesCircle>
          <GameCircle
            name="Crash"
            bgImage={Crash}
            onClick={() => handleGameClick("Crash")}
          />

          <GameCircle
            name="Cassino"
            bgImage={Cassino}
            onClick={() => handleGameClick("Cassino")}
          >
            <TbCards color="#D3D3D3" size={30} />
          </GameCircle>
          <GameCircle
            name="Slots"
            bgImage={Slots}
            onClick={() => handleGameClick("Slots")}
          >
            <BsFillRocketTakeoffFill color="#D3D3D3" size={30} />
          </GameCircle>
          <GameCircle
            name="Sports"
            bgImage={Sports}
            onClick={() => sports()}
          >
            <TbCards color="#D3D3D3" size={30} />
          </GameCircle>
        </GamesCircle>

        <Operations>
          <OperationsHeader>
            <OperationsTitle>ESCOLHA A SALA QUE DESEJA ENTRAR</OperationsTitle>
          </OperationsHeader>
          <OperationsMain>
            <OperationsMainHeader>
              <OperationsMainSubtitle>
                <Topic color="yellow" />
                <OperationsMainSubtitleTxt color="yellow">
                  Possível Entrada
                </OperationsMainSubtitleTxt>
              </OperationsMainSubtitle>
              <OperationsMainSubtitle>
                <Topic color="green" />
                <OperationsMainSubtitleTxt color="green">
                  Entrada confirmada
                </OperationsMainSubtitleTxt>
              </OperationsMainSubtitle>
            </OperationsMainHeader>

            <OperationsMainCards>
            {gamesApi.map((element, index) =>
                element.category === 3 ? ( 
                <Cards
                key={index}
                Pro={!element.all ? user ? true : false : element.all}
                AnalystPhoto={AnalystImg}
                AnalystBandeira={element.analyst_id.bandeira}
                NameAnalyst={element.analyst_id.name}
                Players={playersCounts[index]}
                NumberGreen={element.redsgreens[0].green}
                NumberRed={element.redsgreens[0].red}
                CardImg={element.gameImg}
                LinkGame={`${element.analyst_id.link_analyst}/${element.id}`} 
                type={3}
              />
                ) : null
              )}
             
            </OperationsMainCards>
           {
            user ? <CustomButton1></CustomButton1> : (
              <a href="https://pay.kirvano.com/78198ac5-0b16-4e44-9b86-15aeada3f3dc?utm_campaign=APP&utm_medium=botaohome" target="_blank" rel="noopener noreferrer">
              <CustomButton className="blink-button"></CustomButton>
            </a>
            ) 
          }
          </OperationsMain>

          <InstallButtonContainer>
            <InstallButton href="#" onClick={() => openModal("iOS")}>
              <FaApple size={20} style={{ marginRight: "10px" }} />
              Instalar no iPhone
            </InstallButton>
            <InstallButton href="#" onClick={() => openModal("Android")}>
              <FaAndroid size={20} style={{ marginRight: "10px" }} />
              Instalar no Android
            </InstallButton>

            {showModal && (
              <InstallModal type={modalType} onClose={closeModal} />
            )}
          </InstallButtonContainer>
        </Operations>
      </Container>
      <Menu />
    </>
  );
};

export default Home;

import React, { useState, useEffect, useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { AuthContext } from "../../../context/auth";
import IframeComponent from "../../../components/Iframes/";
import UserImg from "../../../assets/user.jpg";
import Nav from "../../../components/Nav/index";
import { useParams } from "react-router-dom";
import {
  Container,
  General,
  Header,
  ImageHeader,
  Main,
  MainMsg,
  MainName,
  MainBody,
} from "./styles";

const BacBo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [game, setGame] = useState();
  const [currentStrategy, setCurrentStrategy] = useState("");
  const [mensagemAtual, setMensagemAtual] = useState("Analisando padrões...");
  const { id } = useParams();
  const { user, gamesApi } = useContext(AuthContext);

  const getGame = useCallback(() => {
    gamesApi.forEach((element) => {
      if (element.id === id) {
        setGame(element);
      }
    });
  }, [gamesApi, id]);

  useEffect(() => {
    getGame();
  }, [getGame]);

  const fetchBacBoData = async () => {
    setIsLoading(true);
    const apiUrl = "https://api-neobet.onrender.com/scrapping/all";
    try {
      const response = await Axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
        }
      });
  
      const bacboGame = response.data.find(game => game.game === "Bacbo");
      if (!bacboGame) {
        throw new Error("Jogo Bacbo não encontrado na resposta da API.");
      }
  
      
      const resultados = bacboGame.results.split(',').map(item => {
        switch (item.trim()) {
          case 'L':
            return 'Player';
          case 'R':
            return 'Banker';
          case 'Tie':
            return 'Tie';
          default:
            return '';
        }
      }).filter(item => item !== '');
      
      const numerosFiltrados = resultados.filter(item => item === 'Player' || item === 'Banker' || item === 'Tie');
      
      const numerosLimitados = numerosFiltrados.slice(0, 25);
      
      const strategy = processarEstrategia(numerosFiltrados);
      setCurrentStrategy(strategy);
      
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const processarEstrategia = useCallback((numeros) => {
  
    const frases = [
      "Estou buscando uma oportunidade...",
      "Traçando uma nova estratégia...",
      "Digitando...",
      "Investigando tendências",
      "Analisando próxima entrada..."
    ];
    const indiceSorteado = Math.floor(Math.random() * frases.length);

      switch (true) {
        // GALE 2 AZUL
        case (numeros.length >= 6 && numeros[0] === "Banker" && numeros[1] === "Banker" && numeros[2] === "Banker" && numeros[3] === "Banker" && numeros[4] === "Banker" && numeros[5] === "Banker"):
          return "GALE 2 | Entrar no 🔵";

        // GALE 1 AZUL
        case (numeros.length >= 5 && numeros[0] === "Banker" && numeros[1] === "Banker" && numeros[2] === "Banker" && numeros[3] === "Banker" && numeros[4] === "Banker"):
          return "GALE 1 | Entrar no 🔵";

        // ESTRATÉGIA AZUL
        case (numeros.length >= 4 && numeros[0] === "Banker" && numeros[1] === "Banker" && numeros[2] === "Banker" && numeros[3] === "Banker"):
          return "Entrada confirmada no 🔵";

        // WIN NO AZUL
        case (numeros.length >= 5 && numeros[0] === "Player" && numeros[1] === "Banker" && numeros[2] === "Banker" && numeros[3] === "Banker" && numeros[4] === "Banker"):
          return "Última cor: 🔵 | Deu Green!!! 🤑💰 ";

        // GALE 2 VERMELHO
        case (numeros.length >= 6 && numeros[0] === "Player" && numeros[1] === "Player" && numeros[2] === "Player" && numeros[3] === "Player" && numeros[4] === "Player" && numeros[5] === "Player"):
          return "GALE 2 | Entrar no 🔴";

        // GALE 1 VERMELHO
        case (numeros.length >= 5 && numeros[0] === "Player" && numeros[1] === "Player" && numeros[2] === "Player" && numeros[3] === "Player" && numeros[4] === "Player"):
          return "GALE 1 | Entrar no 🔴";

        // ESTRATÉGIA VERMELHO
        case (numeros.length >= 4 && numeros[0] === "Player" && numeros[1] === "Player" && numeros[2] === "Player" && numeros[3] === "Player"):
          return "Entrada confirmada no 🔴";

        // WIN NO VERMELHO
        case (numeros.length >= 5 && numeros[0] === "Banker" && numeros[1] === "Player" && numeros[2] === "Player" && numeros[3] === "Player" && numeros[4] === "Player"):
          return "Última cor: 🔴 | Deu Green!!! 🤑💰 ";


        // ESTRATÉGI AZUL (INVERTIDA)
        case (numeros.length >= 4 && numeros[0] === "Banker" && numeros[1] === "Player" && numeros[2] === "Banker" && numeros[3] === "Player"):
          return "Entrada confirmada no 🔵";
        //WIN AZUL (INVERTIDA)
        case (numeros.length >= 5 && numeros[0] === "Player" && numeros[1] === "Banker" && numeros[2] === "Player" && numeros[3] === "Banker" && numeros[4] === "Player"):
          return "Última cor: 🔵 | Deu Green!!! 🤑💰 ";
        // ESTRATÉGI VERMELHO (INVERTIDA)
        case (numeros.length >= 4 && numeros[0] === "Player" && numeros[1] === "Banker" && numeros[2] === "Player" && numeros[3] === "Banker"):
          return "Entrada confirmada no 🔴";
        //WIN VERMELHO (INVERTIDA)
        case (numeros.length >= 5 && numeros[0] === "Banker" && numeros[1] === "Player" && numeros[2] === "Banker" && numeros[3] === "Player" && numeros[4] === "Banker"):
          return "Última cor: 🔴 | Deu Green!!! 🤑💰";

        //POSSÍVEL ENTRADA (Azul)
        case (numeros.length >= 3 && numeros[0] === "Banker" && numeros[1] === "Banker" && numeros[2] === "Banker"):
          return "ATENÇÃO, Possível Entrada!";

        //POSSÍVEL ENTRADA (Vermelho)
        case (numeros.length >= 3 && numeros[0] === "Player" && numeros[1] === "Player" && numeros[2] === "Player"):
          return "ATENÇÃO, Possível Entrada!";

        default:
          return frases[indiceSorteado];
      }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchBacBoData();
      setMensagemAtual("Digitando...");
    }, 10000);
    
    return () => clearInterval(intervalId);
  }, [fetchBacBoData]);

  return (
    <>

    <Nav />
    <Container>
      <Link to="/cassinoHome">
        <AiOutlineArrowLeft color="#fff" size="32" />
      </Link>
      <General>
        <Header>
          <ImageHeader src={UserImg} alt="" />
        </Header>
        <Main>
          <MainMsg>
            <MainName>{game && game.analyst_id.name}</MainName>
            <MainBody>{currentStrategy || mensagemAtual}</MainBody>
          </MainMsg>
        </Main>
      </General>
      {/* <PanelRoleta strategy={currentStrategy} /> */}
      <IframeComponent link={game && game.link_iframe} />
    </Container>
    </>
  );
};

export default BacBo;
import React from "react";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import Routes from "./routes/";
import Base from "./pages/Base";

import AuthProvider from "./context/auth";

// const cors = require('cors')

// app.use((req, res, next) => {
//   res.header('Access-Control-Allow-Origin', '*');
//   res.header('Access-Control-Allow-Methods', 'GET, PUT, POST, OPTIONS, DELETE');
//   res.header('Acess-Control-Allow-Headers', 'Content-Type');
//   app.use(cors());
//   next();
// });

export default function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Base>
          <ToastContainer autoClose={3000} />
          <Routes />
        </Base>
      </BrowserRouter>
    </AuthProvider>
  );
}

import PropTypes from "prop-types";

import {
  Container,
  Body,
  Header,
  Image,
  ImageGame,
  Strategy,
  StrategyFild,
  StrategyFildIcon,
  StrategyFildText,
} from "./styles";
import IconNormal from "../../assets/Icons/icon01.png";
import IconTurbo from "../../assets/Icons/turboIcon.png";
import IconTime from "../../assets/Icons/timeIcon.png";

import Button from "../ButtonSignal";

const CardStrategy = ({ imgBackground, imgGame, imgGameHeigth, imgGameWidth, updated, canGenerateSignal, values01, values02, values03 }) => {

  return (
    <Container>
      <Body>  
        <Header>
          <Image src={imgBackground} alt="" />
          <ImageGame width={imgGameWidth} height={imgGameHeigth} src={imgGame} alt="" />
        </Header>

        <Strategy>
        <StrategyFild>
          <StrategyFildIcon src={IconNormal} alt="" />
          <StrategyFildText>Normal: {values01 !== undefined ? values01 : ''}</StrategyFildText>
        </StrategyFild>
        <StrategyFild>
          <StrategyFildIcon src={IconTurbo} alt="" />
          <StrategyFildText>Turbo: {values02 !== undefined ? values02 : ''}</StrategyFildText>
        </StrategyFild>
        <StrategyFild>
          <StrategyFildIcon src={IconTime} alt="" />
          <StrategyFildText>Validade: {values03 !== undefined ? values03 : ''}</StrategyFildText>
        </StrategyFild>
      </Strategy>
      </Body>

      <Button updated={updated} canGenerateSignal={canGenerateSignal} />

    </Container>
  );
};

CardStrategy.propTypes = {
  imgBackground: PropTypes.node.isRequired,
  imgGame: PropTypes.string.isRequired,
  imgGameHeigth: PropTypes.string.isRequired,
  imgGameWidth: PropTypes.string.isRequired,
  updated: PropTypes.string.isRequired,
  canGenerateSignal: PropTypes.string.isRequired,
  values01: PropTypes.string.isRequired,
  values02: PropTypes.string.isRequired,
  values03: PropTypes.string.isRequired,
};

export default CardStrategy;
import styled from "styled-components";

export const Container = styled.nav`
  width: 100%;
  display: flex;
  justify-content: ${({ logged }) => (logged ? 'space-between' : 'center')};
  align-items: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1002;
  position: fixed;
  height: 70px;

  @media (max-width: 767px) {
    justify-content: center; /* Mantém centralizado em dispositivos móveis */
  }
`;

export const CenterContent = styled.div`
  display: flex;
  align-items: center;
  gap: 30px; /* Espaçamento de 30px entre a logo e o botão para mobile e desktop */

  @media (min-width: 768px) {
    gap: 130px; /* Aumenta o espaçamento para 50px em dispositivos desktop */
  }
`;

export const Logout = styled.button`
  background: transparent;
  border: none;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;

  @media (max-width: 767px) {
    justify-content: center; /* Mantém a logo centralizada em dispositivos móveis */
  }
`;

export const OptionImg = styled.img`
  height: 20px;
`;

export const Button = styled.button`
  border: none;
  width: 205px;
  height: 43px;
  border-radius: 50px;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 8px;
  background: #14b1a4;
  padding-left: 6px;
  transition: all 450ms ease-in-out;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  &:hover {
    box-shadow: inset 0px 1px 0px 0px rgba(255, 255, 255, 0.4),
      inset 0px -4px 0px 0px rgba(0, 0, 0, 0.2),
      0px 0px 0px 4px rgba(255, 255, 255, 0.2),
      0px 0px 180px 0px #14b1a4;
    transform: translateY(-2px);
  }
`;

export const ButtonSair = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 15px;

  @media (max-width: 767px) {
    position: absolute;
    right: 10px;
    margin-right: 0;
  }
`;

export const Text = styled.span`
  color: #FFF;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  font-family: 'Poppins', sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1px;
`;


import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { HiCheck, HiOutlineCash, HiOutlineLink } from 'react-icons/hi';
import { AiOutlineClose } from "react-icons/ai";
import x from "../../assets/logovsg.svg";
import apostaLogoVerdeBranca from "../../assets/apostaLogoVerdeBranca.png"

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const MainModal = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  margin-bottom: 60px;
  background: rgba(47, 69, 56, 0.5);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  border: 1px solid #14b1a4;
  z-index: 10000;
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 150px;
  position: relative;
  z-index: 10001;
`;

const ModalHeaderImg = styled.img`
  width: 50%;
  margin-top: 60px;
  height: 40px;
`;

const ModalHeaderButton = styled.button`
  background-color: transparent;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 15px;
  right: 15px;
  border: none;
  z-index: 10002;
  @media (max-width: 768px) {
    right: 10px;
    top: 40px;
  }
`;

const ModalHeaderTxt = styled.span`
  font-size: 24px;
  font-weight: bold;
  color: #fff;
`;

const ModalTitleTxt = styled.h1`
  text-transform: uppercase;
  font-size: 32px;
  font-weight: bold;
  color: #14b1a4;
  text-align: center; /* Adicionando alinhamento central */
  @media (max-width: 768px) {
    text-align: center; /* Adicionando alinhamento central em dispositivos móveis */
  }
`;

const ModalSubtitleTxt = styled.div`
  display: flex;
  flex-direction: column; /* Alterado para coluna */
  align-items: center; /* Centralizar itens horizontalmente */
  font-size: 16px;
  color: #fff;
  text-align: center;
  margin: 5px;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between; /* Manter os botões um ao lado do outro */
  width: 100%;
  margin-top: 10px;
`;

const ModalButton = styled.button`
  width: 48%; /* Ajuste para a largura desejada */
  background-color: #14b1a4;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 10px;
  padding: 5px;
  border: none;
`;

const ModalButtonTxt = styled.span`
  text-transform: uppercase;
  margin-left: 5px;
  font-weight: bold;
  color: black;
`;

const ModalSubtitleTxtImg = styled.img`
  width: 100px;
  height: auto;
  margin-top: 10px;
`;

const ModalAccount = ({ onClose, linkGame, nameGame }) => {
  const navigate = useNavigate();

  useEffect(() => {
    handleYesClick(); // Executa automaticamente quando o componente é montado
  }, []);

  const handleNotClick = () => {
    // window.location.href = "https://go.apostaganha.bet/visit/?bta=71580&brand=apostaganha&utm_campaign=neobetapp";
  };

  const handleYesClick = () => {
    navigate(linkGame);
    onClose();
  };

  return (
    <Modal>
      <MainModal>
        <ModalHeader>
          <ModalHeaderImg src={x} alt="" />
          <ModalHeaderButton onClick={onClose}>
            <ModalHeaderTxt>x</ModalHeaderTxt>
          </ModalHeaderButton>
        </ModalHeader>

        <ModalTitleTxt>jÁ TEM UMA CONTA?</ModalTitleTxt>
        <ModalSubtitleTxt>
          Necessário ter uma conta na casa para continuar.
          <ModalSubtitleTxtImg src={apostaLogoVerdeBranca} alt="Aposta Logo" />
        </ModalSubtitleTxt>

        <ModalButtonContainer>
          <ModalButton onClick={() => handleYesClick()}>
            <HiCheck color="#000" size={26} />
            <ModalButtonTxt>Sim</ModalButtonTxt>
          </ModalButton>

          <ModalButton onClick={handleNotClick}>
            <AiOutlineClose color="#000" size={26} />
            <ModalButtonTxt>Não</ModalButtonTxt>
          </ModalButton>
        </ModalButtonContainer>
      </MainModal>
    </Modal>
  );
};

export default ModalAccount;
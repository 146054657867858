import {
    Button,
    Sparkle,
    Text
} from "./styles";
import PropTypes from "prop-types";

const ButtonSignal =({updated, canGenerateSignal})=>{
    return(
        <Button canGenerateSignal={canGenerateSignal} onClick={() => canGenerateSignal && updated()} disabled={!canGenerateSignal}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Sparkle viewBox="0 0 24 24" width="24" height="20" style={{ marginTop: '5px', marginRight: '8px' }}>
            <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 8C8.9 8 8 8.9 8 10C8 11.1 8.9 12 10 12C11.1 12 12 11.1 12 10C12 8.9 11.1 8 10 8ZM16 10C16 6.7 13.3 4 10 4C6.7 4 4 6.7 4 10C4 12.2 5.2 14.1 7 15.2L8 13.5C6.8 12.8 6 11.5 6 10.1C6 7.9 7.8 6.1 10 6.1C12.2 6.1 14 7.9 14 10.1C14 11.6 13.2 12.9 12 13.5L13 15.2C14.8 14.2 16 12.2 16 10ZM10 0C4.5 0 0 4.5 0 10C0 13.7 2 16.9 5 18.6L6 16.9C3.6 15.5 2 12.9 2 10C2 5.6 5.6 2 10 2C14.4 2 18 5.6 18 10C18 13 16.4 15.5 14 16.9L15 18.6C18 16.9 20 13.7 20 10C20 4.5 15.5 0 10 0Z" fill="white"/>
            </svg>
          </Sparkle>
          <Text>Gerar novo sinal</Text>
        </div>
      </Button>
    )
}

ButtonSignal.propTypes = {
    updated: PropTypes.node.isRequired,
    canGenerateSignal: PropTypes.node.isRequired,
};

export default ButtonSignal
import React, { useState, useEffect, useContext, useCallback} from "react";
import {
  Container,
  General,
  Header,
  ImageHeader,
  Main,
  MainName,
  MainBody
} from "./styles";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Link } from "react-router-dom";
import UserImg from "../../../assets/user.jpg";
import IframeComponent from "../../../components/IframeSlots";
import styled from "styled-components";
import LinkGame from "../../../config/linkGames.json";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../context/auth";
import Nav from "../../../components/Nav/index";
import CardStrategy from "../../../components/CardStrategy/";

import ImageBackgroud from "../../../assets/OX/jogo-fortune-ox.jpg";
import ImageGame from "../../../assets/OX/ox.png";

const Fortune = () => {


  const [strategy, setStrategy] = useState({});
  const [canGenerateSignal, setCanGenerateSignal] = useState(true);

  const [turboAtivo, setTurboAtivo] = useState(true); // Começa com o turbo ativo
  const [exibindoMensagemInicial, setExibindoMensagemInicial] = useState(true); // Controla a exibição da mensagem inicial

  const { id } = useParams();
  const { user, gamesApi } = useContext(AuthContext);
  const [game, setGame] = useState();

  useEffect(()=>{
    function getGame(){
      gamesApi.forEach(element => {
        if(element.id === id){
          setGame(element)
        }
      });
    }
    getGame()
  }, [id, gamesApi])

function sortearNumero() {
    return Math.floor(Math.random() * (10 - 4 + 1)) + 4;
}

function validade() {
  const agora = new Date();
  agora.setMinutes(agora.getMinutes() + 3);

  const horas = agora.getHours().toString().padStart(2, '0');
  const minutos = agora.getMinutes().toString().padStart(2, '0');

  return `${horas}:${minutos}`;
}


  const generateStrategy = useCallback(() => {
    try {
      const dadosParaArmazenar = {
        Ox: {
          normal: sortearNumero(),
          turbo: sortearNumero(),
          validade: validade(),
        },
        horarioAtual: Date.now(),
      };
      setStrategy(dadosParaArmazenar);
      localStorage.setItem("Ox", JSON.stringify(dadosParaArmazenar));
      setCanGenerateSignal(false);
    } catch (error) {
      console.error("Erro ao gerar os dados:", error);
    }
  }, []);

  const checkSignalExpiration = useCallback(() => {
    const searchedValue = localStorage.getItem("Ox");
    if (searchedValue !== null) {
      const valores = JSON.parse(searchedValue);
      const horarioFuturo = valores.horarioAtual + 3 * 60 * 1000;
      if (Date.now() >= horarioFuturo) {
        setStrategy({});
        localStorage.removeItem("Ox");
        setCanGenerateSignal(true);
      } else {
        setStrategy(valores);
        setCanGenerateSignal(false);
      }
    } else {
      setCanGenerateSignal(true);
    }
  }, []);

  useEffect(() => {
    checkSignalExpiration(); 
    const interval = setInterval(() => {
      checkSignalExpiration();
    }, 1 * 60 * 1000);
    return () => clearInterval(interval); 
  }, [checkSignalExpiration]);



  return (
    <>

    <Nav />
    <Container>
      <Link style={{zIndex: 999}} to="/slotsHome">
        <AiOutlineArrowLeft color="#fff" size="32" />
      </Link>

  {/*     <General>
        <Header>
          <ImageHeader src={UserImg} alt="" />
        </Header>
        <Main>
          <MainName><AnalystName>{game && game.analyst_id.name}</AnalystName></MainName>
          <MainBody>
            {isTyping ? (
              <span>{mensagemAtual}</span>
            ) : (
              <Message tigrinho>{mensagemAtual}</Message>
            )}
          </MainBody>
        </Main>
      </General> */}

      <CardStrategy
        imgBackground={ImageBackgroud}
        imgGame={ImageGame}
        imgGameHeigth="395"
        imgGameWidth="395"
        updated={generateStrategy}
        canGenerateSignal={canGenerateSignal}
        values01={strategy.Ox ? strategy.Ox.normal : undefined}
        values02={strategy.Ox ? strategy.Ox.turbo : undefined}
        values03={strategy.Ox ? strategy.Ox.validade : undefined}
      />

      <IframeComponent link={game && game.link_iframe}  />   
      </Container>
      </>
  );
};

export default Fortune;

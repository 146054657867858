import styled from "styled-components"

export const Container = styled.div`
  overflow: auto; /* Adicione uma barra de rolagem quando necessário */
  padding: 5px;
  height: 100vh;
  min-height: 100vh;
`;

export const Banner = styled.div`
  background: green;
  height: 200px;
`;
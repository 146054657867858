import {
  Container,
  General,
  Header,
  ImageHeader,
  Main,
  MainMsg,
  MainName,
  MainBody,
  HighlightedText,
} from "./styles";
import React, { useState, useEffect, useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { AuthContext } from "../../../context/auth";
import IframeComponent from "../../../components/Iframes/";
import PanelRoleta from "../BacboPanel";
import UserImg from "../../../assets/user.jpg";
import Nav from "../../../components/Nav/index";
import { useParams } from "react-router-dom";

const Spaceman = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [game, setGame] = useState();
  const [currentStrategy, setCurrentStrategy] = useState("");
  const [mensagemAtual, setMensagemAtual] = useState("Analisando padrões...");
  const { id } = useParams();
  const { user, gamesApi } = useContext(AuthContext);

  const getGame = useCallback(() => {
    gamesApi.forEach((element) => {
      if (element.id === id) {
        setGame(element);
      }
    });
  }, [gamesApi, id]);

  useEffect(() => {
    getGame();
  }, [getGame]);

  const fetchBacBoData = async () => {
    setIsLoading(true);
    const apiUrl = "https://api-neobet.onrender.com/scrapping/all";
    try {
      const response = await Axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
        }
      });
  
      const spacemanGame = response.data.find(game => game.game === "Spaceman");
      if (!spacemanGame) {
        throw new Error("Jogo Spaceman não encontrado na resposta da API.");
      }
  
      const ultimosNumeros = spacemanGame.results.split(',').map(item => parseFloat(item.trim()));
  
      const strategy = processarEstrategia(ultimosNumeros);
      setCurrentStrategy(strategy);
  
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const processarEstrategia = useCallback((numeros) => {

    const frases = [
      "Estou buscando uma oportunidade...",
      "Traçando uma nova estratégia...",
      "Digitando...",
      "Investigando tendências",
      "Analisando próxima entrada..."
    ];
    const indiceSorteado = Math.floor(Math.random() * frases.length);

    switch (true) {
      // ESTRATÉGIA 1
      // GALE 2
      case (numeros.length >= 15 && numeros.slice(0, 10).every(num => parseFloat(num) <= 2.00)):
        return "PROTEÇÃO 2 | Entrada para 2.00x";

      // GALE 1
      case (numeros.length >= 15 && numeros.slice(0, 9).every(num => parseFloat(num) <= 2.00)):
        return "PROTEÇÃO 1 | Entrada para 2.00x";

      // ENTRADA
      case (numeros.length >= 15 && numeros.slice(0, 8).every(num => parseFloat(num) <= 2.00)):
        return "Entrada confirmada! Entrar para 2.00x | Máx 2 Proteções";

      case (numeros.length >= 15 && numeros.slice(0, 7).every(num => parseFloat(num) <= 2.00)):
        return "ATENÇÃO! Possível Entrada!";

      // WIN
      case (numeros.length >= 15 && numeros.slice(1, 9).every(num => parseFloat(num) <= 2.00) && parseFloat(numeros[0]) >= 2.00):
        return "BATEU!! MAIS UM GREEN! 🤑💰";

      default:
        return frases[indiceSorteado];
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchBacBoData();
      setMensagemAtual("Digitando...");
    }, 10000);

    return () => clearInterval(intervalId);
  }, [fetchBacBoData]);

  return (
    <>
      <Nav />
      <Container>
        <Link to="/crashHome">
          <AiOutlineArrowLeft color="#fff" size="32" />
        </Link>

        <General>
          <Header>
            <ImageHeader src={UserImg} alt="" />
          </Header>
          <Main>
            <MainMsg>
              <MainName>{game && game.analyst_id.name}</MainName>
              <MainBody>{currentStrategy || mensagemAtual}</MainBody>
            </MainMsg>
          </Main>
        </General>

        <IframeComponent link={game && game.link_iframe} />
      </Container>
    </>
  );
};

export default Spaceman;

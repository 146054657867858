import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Container,
  General,
  Header,
  ImageHeader,
  Main,
  MainName,
  MainBody
} from "./styles";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Link } from "react-router-dom";
import UserImg from "../../../assets/user.jpg";
import IframeComponent from "../../../components/IframeSlots";
import styled from "styled-components";
import Nav from "../../../components/Nav/index";

import { AuthContext } from "../../../context/auth";
import LinkGame from "../../../config/linkGames.json";
import { useParams } from "react-router-dom";

import CardStrategy from "../../../components/CardStrategy";
import ImageBackgroud from "../../../assets/Tiger/pergaminho01.png";
import ImageGame from "../../../assets/Tiger/Tiger01.png";



const AnalystName = styled.span`
  color: #FFFFFF;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 8px;
  display: block;
`;

const Message = styled.span`
  color: ${(props) => (props.tigrinho ? "#FFFFFF" : "#FFFFFF")};
  font-weight: ${(props) => (props.tigrinho ? "arial" : "normal")};
  font-size: ${(props) => (props.tigrinho ? "16px" : "14px")};
  margin-bottom: ${(props) => (props.tigrinho ? "10px" : "15px")};
  display: block;
`;

const Tiger = () => {
  const { id } = useParams();
  const { user, gamesApi } = useContext(AuthContext);
  const [game, setGame] = useState();


  const [strategy, setStrategy] = useState({});
  const [canGenerateSignal, setCanGenerateSignal] = useState(true);

  useEffect(()=>{
    function getGame(){
      gamesApi.forEach(element => {
        if(element.id === id){
          setGame(element)
        }
      });
    }
    getGame()
  }, [id, gamesApi])


  function sortearNumero() {
    return Math.floor(Math.random() * (10 - 4 + 1)) + 4;
}

function validade() {
  const agora = new Date();
  agora.setMinutes(agora.getMinutes() + 3);

  const horas = agora.getHours().toString().padStart(2, '0');
  const minutos = agora.getMinutes().toString().padStart(2, '0');

  return `${horas}:${minutos}`;
}


  const generateStrategy = useCallback(() => {
    try {
      const dadosParaArmazenar = {
        Tiger: {
          normal: sortearNumero(),
          turbo: sortearNumero(),
          validade: validade(),
        },
        horarioAtual: Date.now(),
      };
      setStrategy(dadosParaArmazenar);
      localStorage.setItem("Tiger", JSON.stringify(dadosParaArmazenar));
      setCanGenerateSignal(false);
    } catch (error) {
      console.error("Erro ao gerar os dados:", error);
    }
  }, []);

  const checkSignalExpiration = useCallback(() => {
    const searchedValue = localStorage.getItem("Tiger");
    if (searchedValue !== null) {
      const valores = JSON.parse(searchedValue);
      const horarioFuturo = valores.horarioAtual + 3 * 60 * 1000;
      if (Date.now() >= horarioFuturo) {
        setStrategy({});
        localStorage.removeItem("Tiger");
        setCanGenerateSignal(true);
      } else {
        setStrategy(valores);
        setCanGenerateSignal(false);
      }
    } else {
      setCanGenerateSignal(true);
    }
  }, []);

  useEffect(() => {
    checkSignalExpiration(); 
    const interval = setInterval(() => {
      checkSignalExpiration();
    }, 1 * 60 * 1000);
    return () => clearInterval(interval); 
  }, [checkSignalExpiration]);

  return (
    <>

    <Nav />
    <Container>
      <Link to="/slotsHome" style={{zIndex: 999}}>
        <AiOutlineArrowLeft color="#fff" size="32" />
      </Link>

    <div style={{marginTop: 20}}>
      <CardStrategy
        imgBackground={ImageBackgroud}
        imgGame={ImageGame}
        imgGameHeight="303"
        imgGameWidth="308"
        updated={generateStrategy}
        canGenerateSignal={canGenerateSignal}
        values01={strategy.Tiger ? strategy.Tiger.normal : undefined}
        values02={strategy.Tiger ? strategy.Tiger.turbo : undefined}
        values03={strategy.Tiger ? strategy.Tiger.validade : undefined}
      />
    </div>

     
      <IframeComponent link={game && game.link_iframe} />
     
    </Container>
    </>
  );
};

export default Tiger;

import { Routes, Route, Navigate } from "react-router-dom";
import Base from "../pages/Base";
import Home from "../pages/Home";
import Bonus from "../pages/Bonus";
import Termos from "../pages/TermosDeUso";
import Gerenciamento from "../pages/Gerenciamento";
import Tutorial from "../pages/Tutorial";
import Live from "../pages/Live";

import PromoRoleta from "../components/PromoRoleta/index";
import ModalPromo from "../components/ModalPromo/PromotionalModal";
import Aviator from "../pages/Games/Aviator";
import Aviator2 from "../pages/Games/Aviator2x";
import AviatorVelas from "../pages/Games/AviatorVelas";

import Space from "../pages/Games/Space";
import Space2x from "../pages/Games/Space2x";
import SpaceVelas from "../pages/Games/SpaceVelas";

import Mines from "../pages/Games/Mines";
import Roletas from "../pages/Games/Roletas";
import RoletaExtreme from "../pages/Games/RoletaExtreme";
import Tiger from "../pages/Games/Tiger";
import FortuneOx from "../pages/Games/Fortune";
import Rabbit from "../pages/Games/Rabbit";
import Dragon from "../pages/Games/DragonTiger";
import Mouse from "../pages/Games/Mouse";

import FortuneDragon from "../pages/Games/FortuneDragon";

import Bacbo from "../pages/Games/Bacbo";

import Login from "../pages/Login";

import CrashHome from "../components/CrashHome";
import SlotsHome from "../components/SlotsHome";
import CassinoHome from "../components/CassinoHome";
import Indicar from "../pages/Indicar";
import SportsHome from "../components/SportsHome";
import PrivateRoute from './pro.routes';

export default function AppRoutes() {
  return (
    <Routes>
      <Route index path="/" element={<Home />} />
      <Route index path="/Login" element={<Login />} />

      <Route index path="/bonus" element={<Bonus />} />
 
      <Route index path="/tutorial" element={<Tutorial />} />
      <Route index path="/indicar" element={<Indicar />} />
      <Route index path="/termos" element={<Termos />} />
      <Route index path="/lives" element={<Live />} />
      <Route index path="/gerenciamento" element={<Gerenciamento />} />

      <Route index path="/crashHome" element={<CrashHome />} />
      <Route index path="/cassinoHome" element={<CassinoHome />} />
      <Route index path="/slotsHome" element={<SlotsHome />} />
      {/* <Route index path="/promotional" element={<ModalPromo />} />
      <Route index path="/sportsHome" element={<SportsHome />} /> */}

      {/* JOGOS DE CRASH */}
      <Route index path="/aviator/:id" element={<Aviator />} />
      <Route index path="/space/:id" element={<Space />} />
      <Route index path="/promoroleta" element={<PromoRoleta />} />
      <Route index path="/dragon/:id" element={<Dragon />} />

      {/* OUTROS JOGOS */}
      <Route index path="/mines/:id" element={<Mines />} />
      <Route index path="/roletas/:id" element={<Roletas />} />
      <Route index path="/roletaExxtreme/:id" element={<RoletaExtreme />} />
      <Route index path="/tiger/:id" element={<Tiger />} />
      <Route index path="/fortuneOx/:id" element={<FortuneOx />} />
      <Route index path="/rabbit/:id" element={<Rabbit />} />
      <Route index path="/mouse/:id" element={<Mouse />} />
      <Route index path="/fortunedragon/:id" element={<FortuneDragon />} />

      <Route index path="/bacbo/:id" element={<Bacbo />} />

      <Route path="*" element={<Home />} />
    </Routes>
  );
}
